import React, { createContext, useState, ReactNode } from 'react';

interface IframeData {
    agentid:string;
    customername: string,
    botcolor: string,
    customercolor: string,
    customerfontcolor:string,
    botfontcolor: string,
    selectedchipcolor:string,
    sendbtncolor:string,
    chipcolor:string,
    languagesrequired:string[],
    botname: string,
    botrole: string,
    boticon: string ,
    botmsgicon:string,
    csmsgicon:string,
    langoption: string,
}

interface IframeContextProps {
  iframeData: IframeData | null;
}

// Create the Chatbot context
export const IframeContext = createContext<IframeContextProps>({
    iframeData: null,
});

// Create the Chatbot provider component
export const IframeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [iframeData] = useState<IframeData | null>( {
        agentid:"" ,
        customername: "",
        botcolor: "",
        customercolor: "",
        customerfontcolor:"",
        botfontcolor: "",
        selectedchipcolor:"",
        sendbtncolor:"",
        chipcolor:"",
        languagesrequired:[],
        botname: "",
        botrole: "",
        boticon: "../src/Images/simechat-logo.png",
        botmsgicon:"",
        csmsgicon:"",
        langoption: "",
    });
  
    return (
      <IframeContext.Provider value={{ iframeData }}>
        {children}
      </IframeContext.Provider>
    );
  };
