import React, { useState, ChangeEvent, useRef } from 'react';
import { Button, Typography, IconButton } from '@material-ui/core';
import style from './chatbody.module.scss';
import closeBtn from "../../Images/close.svg";

interface ResumeUploadProps {
  selectedFile: File | null;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
  onSendResume: (file: File) => void;
}

const ResumeUpload: React.FC<ResumeUploadProps> = ({ selectedFile, setSelectedFile, onSendResume }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [fileSent, setFileSent] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const chipClickRef = useRef<HTMLAudioElement | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const allowedFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    if (file && !allowedFileTypes.includes(file.type)) {
      setErrorMessage('Invalid file type. Only PDF, DOC, and DOCX files are allowed.');
      setSelectedFile(null);
      resetFileInput();
    } else if (file && file.size > 1510000) {
      setErrorMessage('File size cannot exceed 1MB');
      setSelectedFile(null);
      resetFileInput();
    } else {
      setSelectedFile(file || null);
      setErrorMessage('');
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    resetFileInput();
  };

  const handleSendResume = () => {
    playAudio(chipClickRef, 0);
    if (selectedFile) {
      onSendResume(selectedFile);
      setSelectedFile(null);
      setFileSent(true);
      resetFileInput();
    }
  };

  const handleUploadButtonClick = () => {
    playAudio(chipClickRef, 0);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    setErrorMessage('');
  };

  const playAudio = (audioRef: React.RefObject<HTMLAudioElement>, delay: number) => {
    if (audioRef.current) {
      setTimeout(() => {
        if (audioRef.current) {
          audioRef.current.play();
        }
      }, delay);
    }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className={style.uploadResumeMain}>
      <input
        type="file"
        accept=".pdf,.doc,.docx"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
        id="resume-upload-input"
      />
      <div className={style.buttonsContainer}>
        <Button
          variant="contained"
          className={`${style.uploadBtns} ${!fileSent ? style.enabledButton : style.disabledButton}`}
          onClick={handleUploadButtonClick}
          disabled={fileSent}
        >
          Upload
        </Button>
        <Button
          onClick={handleSendResume}
          disabled={!selectedFile || fileSent}
          variant="contained"
          className={`${style.uploadBtns} ${selectedFile ? style.enabledButton : style.disabledButton}`}
        >
          Send
        </Button>
      </div>
      {selectedFile && (
        <div className={style.selctedFile}>
          <Typography className={style.fileName} style={{ marginRight: '10px', fontSize: '14px', fontFamily: '"Montserrat", sans-serif' }}>
            {selectedFile.name}
          </Typography>
          {!fileSent && (
            <IconButton onClick={handleRemoveFile} size="small">
              <img src={closeBtn} alt="" style={{ height: '1rem', width: '1rem' }} />
            </IconButton>
          )}
        </div>
      )}
      {errorMessage && (
        <Typography style={{ color: 'red', marginBottom: '10px', marginLeft: '30px' }}>{errorMessage}</Typography>
      )}
      <audio ref={chipClickRef} id="chipClickSound" controls={false} style={{ display: 'none' }}>
        <source src="/Sounds/chipClick.mp3" type="audio/mp3" />
      </audio>
    </div>
  );
};

export default ResumeUpload;
