import React from 'react';

interface ResponseRendererProps {
  htmlContent: string;
}

const ResponseRenderer: React.FC<ResponseRendererProps> = ({ htmlContent }) => {
  // htmlContent = "Our office in Kochi is located at Unit 305 and 306, World Trade Center, Infopark, Kerala 682042. Feel free to visit us anytime to check out our abode of design and meet our team. We make sure everyone feels the same and we hope you will too. <br>Source :<a href ='https://www.simelabs.com/company/ 'target=\"_blank\" > https://www.simelabs.com/company/ </a>"
  
  // The parseAndRenderHTML function takes an HTML string, parses it, and returns a React node.
  // const parseAndRenderHTML = (htmlString: string): React.ReactNode => {
  //   console.log("htmlstring = ",htmlString);
  //   let modifiedString = htmlString.split("/n");
  //   console.log(modifiedString);
    
  //   // Create a DOMParser instance to parse the HTML string.
  //   const parser = new DOMParser();
  //   console.log("parser = ",parser);
  //   let doc = parser.parseFromString(htmlString, 'text/html');
  //   console.log("doc after parser = ",doc);
    
    
  //   // Initialize an array to store React nodes.
  //   const elements: React.ReactNode[] = [];

  //   console.log(doc.body.childNodes)
  //   // Iterate through the child nodes of the parsed HTML.
  //   // modifiedString.map((item,index)=>{
  //   //   if(index===modifiedString.length-1){
  //   //     return(
  //   //       elements.push(
  //   //         <a
  //   //            key={index}
  //   //             href={item.split(":")[1]}
  //   //               target="_blank"
  //   //               rel="noopener noreferrer"
  //   //               className='test'
  //   //             >
  //   //              {item}
                  
  //   //           </a>
  //   //       )
  //   //     )
  //   //   }
  //   //   else{
  //   //     return(
  //   //       elements.push(
  //   //         <span
  //   //            key={index}
  //   //               rel="noopener noreferrer"
  //   //               className='test'
  //   //             >
  //   //              {item}
                  
  //   //           </span>
  //   //       )
  //   //     )
  //   //   }
  //   // })

  //   // Return the array of React nodes.
  //   return <>{elements}</>;
  // };

  // Render the parsed HTML content within a <div>.
  return <div dangerouslySetInnerHTML={{__html: htmlContent}} className='reponseRenderer' ></div>;
};

export default ResponseRenderer;

